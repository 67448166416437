import { useHistory } from 'react-router-dom';
import { LinkTagButton, NakedButton, CircularButton, YellowButton } from '@cb/apricot-react-button';
import { Icon } from '@cb/apricot-react-icon';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useFunctionalContentItems } from '@satui/drupal-profile';
import { Spinner } from '@cb/apricot-react-spinner';
import { Tooltip } from '@cb/apricot-react-tooltip';
import { useContext, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { CAROUSEL_BUTTON_KEYS, PDF_KEYS } from '../../../../../drupal';
import { IScoreCardSummary } from '../../types';
import { ITestingRulesStatusRes } from '../../../../../../types/testingRulesStatus';
import questionbankRoutes from '../../../questionbank/routes';
import { ITailoredQuestionParams } from '../../../../../../contexts/questionbank/api/types';
import ScoreReportPDF from '../../../pdf/pdf';
import { formatFileName } from '../../../utils/formatFileName';
import { loggerContext } from '../../../../../../contexts/api/MetricLoggingProvider';

interface ICarouselScoreCard {
  scoreCardSummary: IScoreCardSummary;
  testingRulesStatus?: ITestingRulesStatusRes;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  setDetailsLink: React.Dispatch<React.SetStateAction<string>>;
}

/**
 * Individual score card component. Displays basic test info, such as total
 * and section scores, test name, and date. Provides links to score details and
 * Khan Academy.
 */
const CarouselScoreCard: React.FC<ICarouselScoreCard> = ({
  scoreCardSummary,
  testingRulesStatus,
  setModalState,
  setDetailsLink,
}) => {
  const history = useHistory();
  const cookies = new Cookies();
  const [tempLoggedIn, setTempLoggedIn] = useState(false);

  const {
    id,
    asmtId,
    rosterEntryId,
    asmtFamilyCd,
    asmtEventId,
    date,
    asmtSubmissionStartTime,
    title,
    totalScore,
    totalScoreRange,
    readingScore,
    readingScoreRange,
    mathScore,
    mathScoreRange,
    detailsLink,
    khanLink,
    questionBankData,
    program,
    testVersion,
    ariaLabel,
  } = scoreCardSummary;

  const { logMetrics } = useContext(loggerContext);

  /**
   * Checks if jwt is present in browser. If present, set the tempLoggedIn flag to true.
   */
  useEffect(() => {
    const jwt = cookies.get('__Host-tempJwtToken') as string | undefined;
    if (jwt) {
      setTempLoggedIn(true);
    } else {
      setTempLoggedIn(false);
    }
  }, [cookies]);

  const onTailoredClick = () => {
    const getAsmtEventId = () => {
      if (asmtId === 118) return 99;
      if (asmtId === 119) return 100;
      if (asmtId === 120) return 102;

      return 0;
    };

    const tailoredQuestionParams: ITailoredQuestionParams = {
      rosterEntryId,
      asmtEventId: getAsmtEventId(),
      domainScores: questionBankData!.domainScores,
      external_ids: questionBankData!.external_ids,
    };

    history.push(questionbankRoutes.tailored, { title, date, tailoredQuestionParams });
  };

  const reportClickEvent = (eventName: string) => {
    logMetrics(rosterEntryId, asmtFamilyCd, asmtEventId, asmtId.toString(), eventName);
  };

  const { drupalContent } = useFunctionalContentItems(Object.values({ ...CAROUSEL_BUTTON_KEYS, ...PDF_KEYS }));

  if (!drupalContent) {
    return <Spinner />;
  }

  const {
    [CAROUSEL_BUTTON_KEYS.REVIEW_TEST]: reviewBtnContent,
    [CAROUSEL_BUTTON_KEYS.KHAN_LINK]: khanLinkContent,
    [PDF_KEYS.PDF_LEGAL]: legalContent,
    [PDF_KEYS.PDF_RESOURCE]: resourceContent,
    [PDF_KEYS.PDF_COPYWRIGHT]: copywrightContent,
  } = drupalContent;

  return (
    <li id={id} role="group" className="carousel-score-card cb-white-bg" aria-label={ariaLabel || ''}>
      <div className="header">
        <p>{program}</p>
        {!tempLoggedIn && (
          <PDFDownloadLink
            document={
              <ScoreReportPDF
                scoreCardSummary={scoreCardSummary}
                legalContent={legalContent}
                resourceContent={resourceContent}
                copywrightContent={copywrightContent}
              />
            }
            fileName={formatFileName(title, asmtSubmissionStartTime, scoreCardSummary)}
            aria-label="Score report PDF download"
          >
            <CircularButton
              icon="download"
              label="download button"
              className="download-btn"
              onClick={() => reportClickEvent('pdf_download')}
              aria-hidden
              tabIndex={-1}
            />
          </PDFDownloadLink>
        )}
      </div>
      <div className="test-version">
        <div className="version">{testVersion}</div>
        <div className="date">{date}</div>
      </div>
      <div className="body ">
        <div className="total-score">
          <h4>YOUR TOTAL SCORE</h4>
          <p className="score">{totalScore}</p>
          <span className="sr-only">In a range</span>
          <a href={`#${rosterEntryId}-tooltip`} id={`${rosterEntryId}-tooltip`}>
            <p className="score-range cb-roboto-light cb-gray1-color cb-link-black cb-link-dotted">{totalScoreRange}</p>
          </a>
          <Tooltip trigger={`${rosterEntryId}-tooltip`} className="tooltip-wrapper" placement="bottom">
            <p>This range reflects the minimum and maximum total score you can get with this assessment.</p>
          </Tooltip>
        </div>
        <div className="section-scores">
          <div className="reading-writing border-bottom">
            <div>
              <p>Reading and Writing</p>
              <span className="sr-only">In a range</span>
              <p className="section-score-range">{readingScoreRange}</p>
            </div>
            <div className="score-display">
              <span className="sr-only">Your score</span>
              <p className="section-score">{readingScore}</p>
            </div>
          </div>
          <div className="math">
            <div>
              <p>Math</p>
              <span className="sr-only">In a range</span>
              <p className="section-score-range">{mathScoreRange}</p>
            </div>
            <div className="score-display">
              <span className="sr-only">Your score</span>
              <p className="section-score">{mathScore}</p>
            </div>
          </div>
        </div>
        <div className="footer">
          {asmtId === 119 && !testingRulesStatus ? (
            <YellowButton
              onClick={() => {
                setModalState(true);
                setDetailsLink(detailsLink);
              }}
              small
              className="details-button"
            >
              {reviewBtnContent.overview || ''}
            </YellowButton>
          ) : (
            <YellowButton className="details-button" small onClick={() => history.push(detailsLink)}>
              {reviewBtnContent.overview || ''}
            </YellowButton>
          )}
          {asmtId !== 120 && (
            <LinkTagButton
              className="explore-button"
              offScreen="Practice on Khan Academy"
              href={khanLink}
              target="_blank"
              small
              onClick={() => reportClickEvent('khan_view')}
            >
              {khanLinkContent.extDescription || ''}
              <Icon name="north-east" />
            </LinkTagButton>
          )}
          {questionBankData && (
            <NakedButton className="cb-black1-color tailored-questions-link" onClick={onTailoredClick} small>
              <Icon name="bullet-list" />
              <span className="tailored-questions-text">Practice Specific Questions</span>
            </NakedButton>
          )}
        </div>
      </div>
    </li>
  );
};

export default CarouselScoreCard;

//              <span className="cb-glyph cb-north-east tailored-questions-glyph" />
