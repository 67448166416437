import { Breadcrumbs, Crumb } from '@cb/apricot-react-breadcrumbs';
import { useHistory } from 'react-router-dom';
import { Select } from '@cb/apricot-react-forms';
import ROUTES from '../../../../../../routes';
import { ExampleEssay, ExamplePrompt } from '../types';
import { exampleEssaySelectValues, selectedPromptSelectValues } from '../constants';

interface IEssayExampleHeader {
  essayTitle: string;
  selectedPrompt: ExamplePrompt;
  setSelectedPrompt: React.Dispatch<React.SetStateAction<ExamplePrompt | undefined>>;
  example: ExampleEssay;
  setExample: React.Dispatch<React.SetStateAction<ExampleEssay>>;
}

const EssayExamplesHeader: React.FC<IEssayExampleHeader> = ({
  essayTitle,
  selectedPrompt,
  setSelectedPrompt,
  example,
  setExample,
}) => {
  const history = useHistory();

  return (
    <header>
      <div className="banner">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-10 offset-md-1">
              <h1>SAT Essay Examples</h1>
              <div className="example-nav">
                <Select
                  onChange={(value) => setSelectedPrompt(value as ExamplePrompt)}
                  values={selectedPromptSelectValues}
                  value={selectedPrompt}
                  aria-label="Essay prompt"
                />
                <Select
                  onChange={(value) => setExample(value as ExampleEssay)}
                  values={exampleEssaySelectValues[parseInt(selectedPrompt, 10) - 1]}
                  value={example}
                  aria-label="Example student response"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Breadcrumbs pageTitle="" className="essay-breadcrumbs cb-gray5-bg">
        <Crumb title="My Tests" onClick={() => history.push(ROUTES.dashboard)} />
        <Crumb title={essayTitle} />
      </Breadcrumbs>
    </header>
  );
};

export default EssayExamplesHeader;
