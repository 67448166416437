import { Illustration } from '@cb/apricot-react-illustration';
import { NakedButton } from '@cb/apricot-react-button';
import { useHistory } from 'react-router-dom';
import { Glyph } from '@cb/apricot-react-glyph';
import { useEffect } from 'react';
import { Icon } from '@cb/apricot-react-icon';
import { Tooltip } from '@cb/apricot-react-tooltip';
import { IScoreEssayRes } from '../../../../../../types/scores';
import essayRoutes from '../../routes';
import ScoringRubricGuide from '../../scoringRubricGuide';

const Header: React.FC<{ setOpen: React.Dispatch<React.SetStateAction<boolean>> }> = ({ setOpen }) => {
  return (
    <div className="rubric-header">
      <div>
        <h3 id="rubric-title">Scoring Rubric Guide</h3>
        <Icon name="info-circle" id="rubric-tooltip" />
        <Tooltip trigger="rubric-tooltip" placement="top">
          <p>
            You can self score your practice essay, but the SAT Essay response you&apos;ll submit on test day gets
            scored by two professionals using a carefully designed process.
          </p>
          {/* <a
            href="https://satsuite.collegeboard.org/sat/scores/understanding-scores/essay"
            rel="noreferrer"
            target="_blank"
          >
            Learn about essay scoring.
          </a> */}
        </Tooltip>
      </div>
      <Glyph
        name="x-mark"
        onClick={() => {
          setOpen(false);
        }}
      />
    </div>
  );
};

const MobileSidebar: React.FC<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  score: IScoreEssayRes;
}> = ({ open, setOpen, score }) => {
  const history = useHistory();

  useEffect(() => {
    return () => {
      setOpen(false);
    };
  }, []);

  return (
    <div className={`essay-mobile-sidebar ${open && 'open'}`}>
      <ScoringRubricGuide header={<Header setOpen={setOpen} />} />
      <div className="view-examples-card">
        <div className="illustration">
          <Illustration name="practice" circular shadow />
        </div>
        <div className="body">
          <h3>View Student Examples</h3>
          <p>See other students&apos; essay responses and the scores they received.</p>
        </div>
        <NakedButton onClick={() => history.push(`${essayRoutes.examples}?${score.rosterEntryId}`)}>
          <span className="cb-glyph cb-right" aria-hidden="false">
            <span className="sr-only">View Student Examples link</span>
          </span>
        </NakedButton>
      </div>
    </div>
  );
};

export default MobileSidebar;
