import { useContext } from 'react';
import { Illustration } from '@cb/apricot-react-illustration';
import { DrupalFunctionalContent } from '@satui/drupal-profile';
import { authContext } from '@satreg/auth';
import '@cb/apricot/CBCard';
import '@cb/apricot/CBLogo';
import questionbankRoutes from '../../questionbank/routes';
import '../styles/index.scss';

interface IMoreResources {
  registerSatContent: DrupalFunctionalContent;
  studentQuestionBankContent: DrupalFunctionalContent;
  digitalSatContent: DrupalFunctionalContent;
  bluebookContent: DrupalFunctionalContent;
}

/*
    Static More Resources cards from SAT-149667
    Notes:
      - 07/07/2022 - Cards will remain static for now with Drupal
*/
const MoreResources: React.FC<IMoreResources> = ({
  registerSatContent,
  studentQuestionBankContent,
  digitalSatContent,
  bluebookContent,
}) => {
  const { sessionId } = useContext(authContext);

  return (
    <>
      {/* Register for SAT Card */}
      {sessionId && (
        <div className="col-md-6 col-sm-12 col-xs-12 more-resources-card cb-margin-top-24">
          <div className="cb-card">
            <div className="flex-start cb-margin-right-16">
              <div className="cb-card-header cb-margin-right-16">
                <Illustration name="laptop" circular shadow />
              </div>
              <div className="cb-card-content more-resources-content">
                <h4 className="cb-card-title cb-font-weight-bold">{registerSatContent.altTitle || ''}</h4>
                <div>
                  <p>{registerSatContent.extDescription || ''}</p>
                </div>
              </div>
            </div>
            <a
              href={registerSatContent.intDescription || ''}
              role="button"
              className="cb-btn cb-btn-naked cb-glyph cb-glyph-sm cb-black1-color btn-border-removal"
              target="_blank"
              rel="noreferrer"
            >
              <span className="cb-glyph cb-right" aria-hidden="false">
                <span className="sr-only">SAT registration link</span>
              </span>
            </a>
          </div>
        </div>
      )}
      {/* Student Question Bank Card */}
      <div className="col-md-6 col-sm-12 col-xs-12 more-resources-card cb-margin-top-24 ">
        <div className="cb-card">
          <div className="flex-start cb-margin-right-16">
            <div className="cb-card-header cb-margin-right-16">
              <Illustration name="checklist-device" circular shadow />
            </div>
            <div className="cb-card-content more-resources-content">
              <h3 className="cb-card-title cb-font-weight-bold">{studentQuestionBankContent.altTitle || ''}</h3>
              <div>
                <p>{studentQuestionBankContent.extDescription || ''}</p>
              </div>
            </div>
          </div>
          <a
            href={`${window.location.origin}${questionbankRoutes.search}`}
            role="button"
            className="cb-btn cb-btn-naked cb-glyph cb-glyph-sm cb-black1-color btn-border-removal"
            target="_blank"
            rel="noreferrer"
          >
            <span className="cb-glyph cb-right" aria-hidden="false">
              <span className="sr-only">Student Question Bank</span>
            </span>
          </a>
        </div>
      </div>
      {/* Blue Book Card */}
      <div className="col-md-6 col-sm-12 col-xs-12 more-resources-card cb-margin-top-24 ">
        <div className="cb-card">
          <div className="flex-start cb-margin-right-16">
            <div className="cb-card-header cb-margin-right-16">
              <Illustration name="computer" circular shadow />
            </div>
            <div className="cb-card-content more-resources-content">
              <h3 className="cb-card-title cb-font-weight-bold">{bluebookContent.altTitle || ''}</h3>
              <div>
                <p>{bluebookContent.extDescription || ''}</p>
              </div>
            </div>
          </div>
          <a
            href={bluebookContent.intDescription || ''}
            role="button"
            className="cb-btn cb-btn-naked cb-glyph cb-glyph-sm cb-black1-color btn-border-removal"
            target="_blank"
            rel="noreferrer"
          >
            <span className="cb-glyph cb-right" aria-hidden="false">
              <span className="sr-only">Bluebook digital testing</span>
            </span>
          </a>
        </div>
      </div>
      {/* KHAN Academy Card */}
      <div className="col-md-6 col-sm-12 col-xs-12 more-resources-card cb-margin-top-24 ">
        <div className="cb-card">
          <div className="flex-start cb-margin-right-16">
            <div className="cb-card-header cb-margin-right-16">
              <Illustration name="khan-academy" />
            </div>
            <div className="cb-card-content more-resources-content">
              <h3 className="cb-card-title cb-font-weight-bold">{digitalSatContent.altTitle || ''}</h3>
              <div>
                <p>{digitalSatContent.extDescription || ''}</p>
              </div>
            </div>
          </div>
          <a
            href={digitalSatContent.intDescription || ''}
            role="button"
            className="cb-btn cb-btn-naked cb-glyph cb-glyph-sm cb-black1-color btn-border-removal"
            target="_blank"
            rel="noreferrer"
          >
            <span className="cb-glyph cb-right" aria-hidden="false">
              <span className="sr-only">Khan Academy</span>
            </span>
          </a>
        </div>
      </div>
    </>
  );
};

export default MoreResources;
