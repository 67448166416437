import { createContext, useContext, useEffect, useState } from 'react';
import { IQbankTableData } from '../api/types';
import { qbankTableDataContext } from '../api/QbankTableDataProvider';
import { IFilterCheckbox, IFilterSkillGroup } from './types';
import { getInitialDifficultyFilters, getInitialSkillFilters } from './utils';
import { searchQueryContext } from '../searchQuery';
import { qbankLiveItemsDataContext } from '../api/QbankLiveItemsDataProvider';

export type PageSize = 10 | 30 | 50;

interface IFilteredTableDataContext {
  filteredTableData: IQbankTableData[];
  difficultyFilters: IFilterCheckbox[];
  setDifficultyFilters: React.Dispatch<React.SetStateAction<IFilterCheckbox[]>>;
  skillFilters: IFilterSkillGroup[];
  setSkillFilters: React.Dispatch<React.SetStateAction<IFilterSkillGroup[]>>;
  markCheckedState: (index: number, isChecked: boolean) => void;
  showSelectedQuestions: boolean;
  setShowSelectedQuestions: React.Dispatch<React.SetStateAction<boolean>>;
  filterLiveItems: boolean;
  setFilterLiveItems: React.Dispatch<React.SetStateAction<boolean>>;
  pageSize: PageSize;
  setPageSize: React.Dispatch<React.SetStateAction<PageSize>>;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const defaultState: IFilteredTableDataContext = {
  filteredTableData: [],
  difficultyFilters: [],
  setDifficultyFilters: () => {},
  skillFilters: [],
  setSkillFilters: () => {},
  markCheckedState: () => {},
  showSelectedQuestions: false,
  setShowSelectedQuestions: () => {},
  filterLiveItems: false,
  setFilterLiveItems: () => {},
  pageSize: 10,
  setPageSize: () => {},
  currentPage: 1,
  setCurrentPage: () => {},
};

export const filteredTableDataContext = createContext<IFilteredTableDataContext>(defaultState);
const { Provider } = filteredTableDataContext;

/**
 * Provides table data to the results table, taking into account the filters which the user has selected
 * @param param0
 * @returns
 */
const FilteredTableDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { domainScores } = useContext(searchQueryContext);
  const { qbankTableData, isLoaded } = useContext(qbankTableDataContext);
  const { qbankLiveItemsData } = useContext(qbankLiveItemsDataContext);

  const [filteredTableData, setFilteredTableData] = useState<IQbankTableData[]>(qbankTableData);
  const [difficultyFilters, setDifficultyFilters] = useState<IFilterCheckbox[]>(getInitialDifficultyFilters());
  const [skillFilters, setSkillFilters] = useState<IFilterSkillGroup[]>([]);
  const [showSelectedQuestions, setShowSelectedQuestions] = useState(false);
  const [checkedStateFlag, setCheckedStateFlag] = useState(false);
  const [filterLiveItems, setFilterLiveItems] = useState(false);
  const [pageSize, setPageSize] = useState<PageSize>(10);
  const [currentPage, setCurrentPage] = useState(1);

  /**
   * Function to set the checked state of a question to true or false
   * @param index
   * @param isChecked
   */
  const markCheckedState = (index: number, isChecked: boolean) => {
    const newState = [...filteredTableData];
    newState[index].checked = isChecked;
    setCheckedStateFlag(!checkedStateFlag);
    setFilteredTableData(newState);
  };

  // if table data is loaded from the api, set it to state here
  useEffect(() => {
    if (isLoaded && qbankTableData.length) {
      setFilteredTableData(qbankTableData);
    }
  }, [qbankTableData, isLoaded]);

  // if domain scores are loaded, set the skill filters
  useEffect(() => {
    if (domainScores.length) setSkillFilters(getInitialSkillFilters(domainScores));
  }, [domainScores]);

  // apply filters to table data
  useEffect(() => {
    if (qbankTableData.length) {
      const checkedDifficultyFilters: string[] = [];
      const checkedSkillFilters: string[] = [];

      // put all checked difficulties into an array
      difficultyFilters.forEach((filter) => {
        if (filter.checked) checkedDifficultyFilters.push(filter.value);
      });
      // put all checked skills into an array
      skillFilters.forEach((domain) => {
        domain.checkboxes.forEach((filter) => {
          if (filter.checked) checkedSkillFilters.push(filter.value);
        });
      });

      // filter on difficulty
      const difficultyFilteredData = qbankTableData.filter((question) => {
        let returnValue = false;

        // if no filters are applied, return all questions
        if (!checkedDifficultyFilters.length) {
          returnValue = true;
          return returnValue;
        }

        // eslint-disable-next-line consistent-return
        checkedDifficultyFilters.forEach((filter) => {
          // if a question matches a filter, stop the loop and return true
          if (filter === question.difficulty) {
            returnValue = true;
            return returnValue;
          }
        });

        // if a question doesn't match any filters, return false (initialized value)
        return returnValue;
      });

      // use the difficulty filtered question set to filter on skill
      const skillFilteredData = difficultyFilteredData.filter((question) => {
        let returnValue = false;

        if (!checkedSkillFilters.length) {
          returnValue = true;
          return returnValue;
        }

        // eslint-disable-next-line consistent-return
        checkedSkillFilters.forEach((filter) => {
          if (filter === question.skill.trim()) {
            returnValue = true;
            return returnValue;
          }
        });

        return returnValue;
      });

      // check if displaying only selected questions
      const selectedFilteredData = skillFilteredData.filter((question) => {
        if (showSelectedQuestions) {
          if (question.checked) return true;
          return false;
        }
        return true;
      });

      // filter out live questions if filter is set
      const filteredData = selectedFilteredData.filter((question) => {
        if (filterLiveItems && question.externalId) {
          if (qbankLiveItemsData.includes(question.externalId)) {
            return false;
          }
        }
        return true;
      });

      setFilteredTableData(filteredData);
    }
  }, [checkedStateFlag, difficultyFilters, skillFilters, showSelectedQuestions, filterLiveItems]);

  return (
    <Provider
      value={{
        filteredTableData,
        difficultyFilters,
        setDifficultyFilters,
        skillFilters,
        setSkillFilters,
        markCheckedState,
        showSelectedQuestions,
        setShowSelectedQuestions,
        filterLiveItems,
        setFilterLiveItems,
        pageSize,
        setPageSize,
        currentPage,
        setCurrentPage,
      }}
    >
      {children}
    </Provider>
  );
};

export default FilteredTableDataProvider;
