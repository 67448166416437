import { useContext, useEffect, useState } from 'react';
import { Spinner } from '@cb/apricot-react-spinner';
import { useFunctionalContentItems } from '@satui/drupal-profile';
import { HtmlMessage } from '@satreg/drupal';
import { webAnalyticsContext } from '@satreg/analytics';
import { LeftTextBand } from '@cb/apricot-react-band';
import { LinkTagButton } from '@cb/apricot-react-button';
import { Icon } from '@cb/apricot-react-icon';
import { DASHBOARD_KEYS } from '../../../drupal';
import formatScoresData from './utils/formatScoresData';
import { scoresContext } from '../../../../contexts/api/ScoresProvider';
import MoreResources from './moreResources';
import { IHomeScoresData } from './types';
import ScoresCarousel from './scoresCarousel';
import './styles/index.scss';

/**
 * Landing page upon authenticating into the application. Contains score cards and
 * More Resources section.
 */
const DashboardPage: React.FC = () => {
  const { scores, studentInfo, isLoaded, error } = useContext(scoresContext);
  const [scoresData, setScoreData] = useState<IHomeScoresData>();
  const [noScoresView, setNoScoresView] = useState<boolean>();
  const { reportPageData } = useContext(webAnalyticsContext);

  // update title
  useEffect(() => {
    document.title = 'MyPractice - Dashboard';
  }, []);

  // Throw error is scores service fails.
  useEffect(() => {
    if (error) throw new Error('Scores service unavailable');
  }, [error]);

  // checks ms response for scores and formats them for the dashboard ui
  useEffect(() => {
    if (isLoaded) {
      if (scores.length !== 0) {
        setScoreData(formatScoresData(scores, studentInfo));
        setNoScoresView(false);
      } else {
        setNoScoresView(true);
      }
    }
  }, [isLoaded, scores]);

  // if student has no scores, report it as unique page to WA
  useEffect(() => {
    if (noScoresView !== undefined) {
      if (noScoresView) reportPageData({ flowCode: 'satpractice', pageCode: 'dashboard', appViewCode: 'no-scores' });
      else reportPageData({ flowCode: 'satpractice', pageCode: 'dashboard', appViewCode: 'scores' });
    }
  }, [noScoresView]);

  const { drupalContent } = useFunctionalContentItems(Object.values(DASHBOARD_KEYS));

  // display longer banner text at medium screen sizes
  useEffect(() => {
    if (drupalContent) {
      const bannerBody = document.querySelector('.dashboard-banner')?.querySelector('.col-xs-12') as HTMLDivElement;
      bannerBody.classList.remove('col-md-6', 'offset-md-1');
    }
  }, [drupalContent]);

  if (!drupalContent) {
    return <Spinner />;
  }

  const { [DASHBOARD_KEYS.NO_SCORES]: messageContent, [DASHBOARD_KEYS.DASHBOARD_HEADER]: headerContent } =
    drupalContent;

  return (
    <div>
      <div className="cb-gray5-bg">
        <LeftTextBand
          title={headerContent.extDescription || ''}
          description={headerContent.intDescription || ''}
          condensed
          className="cb-blue2-bg cb-white-color dashboard-banner"
        />
        <div id="DashboardLayout" className=" inner-wrapper">
          {!noScoresView &&
            (scoresData ? (
              <ScoresCarousel
                satScoreCardSummaries={scoresData.satScoreCardSummaries}
                psatScoreCardSummaries={scoresData.psatScoreCardSummaries}
                essayCardSummaries={scoresData.essayCardSummaries}
              />
            ) : (
              <span className="cb-margin-top-48 center-alignment">
                <Spinner />
              </span>
            ))}
          {noScoresView ? (
            <div className="cb-margin-top-24 no-scores">
              <div className="no-scores-content">
                <HtmlMessage className="text-content" message={messageContent.overview || ''} />
                <LinkTagButton target="_blank" href="https://satsuite.collegeboard.org/practice/bluebook">
                  <Icon name="download" />
                  <p>Download Bluebook</p>
                </LinkTagButton>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <MoreResources />
    </div>
  );
};

export default DashboardPage;
