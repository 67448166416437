import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { Spinner } from '@cb/apricot-react-spinner';
import { typedTableComponents } from '@cb/apricot-react-table';
import { InfoNotification } from '@cb/apricot-react-notification';
import { NakedButton } from '@cb/apricot-react-button';
import { Pager } from '@cb/apricot-react-pager';
import { searchQueryContext } from '../../../../../../contexts/questionbank/searchQuery';
import { qbankTableDataContext } from '../../../../../../contexts/questionbank/api/QbankTableDataProvider';
import { IQbankTableData } from '../../../../../../contexts/questionbank/api/types';
import getQuestionbankTableColumns from './columns';
import QbankQuestionModal from './modal';
import { noResultsError } from '../constants';
import { filteredTableDataContext } from '../../../../../../contexts/questionbank/filteredTableData';
import { exportQuestionSetContext } from '../../../../../../contexts/questionbank/exportQuestionSet';
import { appendTagAriaLabels, generateTags } from '../utils';
import { paginate } from './utils';
import FilterTagSet from './filterTagSet';
import './styles/index.scss';

interface IQuestionbankTable {
  windowWidth: number;
  showKsd: boolean;
}

const QuestionbankTable: React.FC<IQuestionbankTable> = ({ windowWidth, showKsd }) => {
  const { Table } = typedTableComponents<IQbankTableData>();
  const { assessment, section, domainScores } = useContext(searchQueryContext);
  const { getQbankTableData, isLoaded } = useContext(qbankTableDataContext);
  const {
    filteredTableData,
    showSelectedQuestions,
    setShowSelectedQuestions,
    difficultyFilters,
    setDifficultyFilters,
    skillFilters,
    setSkillFilters,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    filterLiveItems,
  } = useContext(filteredTableDataContext);
  const { exportData } = useContext(exportQuestionSetContext);
  const [tags, setTags] = useState<string[]>([]);
  const [paginatedQuestions, setPaginatedQuestions] = useState(filteredTableData);
  const [pagerPadding, setPagerPadding] = useState(72);

  const onTagClick = (tagName: string, e: SyntheticEvent) => {
    e.preventDefault();
    const filter = {
      value: tagName,
      checked: false,
    };

    setDifficultyFilters(
      difficultyFilters.map((currentFilter) => {
        if (currentFilter.value === filter.value) return filter;
        return currentFilter;
      }),
    );
    setSkillFilters(
      skillFilters.map((currentFilter) => {
        return {
          domain: currentFilter.domain,
          checkboxes: currentFilter.checkboxes.map((checkbox) => {
            if (checkbox.value === filter.value) return filter;
            return checkbox;
          }),
        };
      }),
    );
  };

  useEffect(() => {
    if (assessment && section && domainScores.length) {
      let domainString = '';
      domainScores.forEach((domain, index) => {
        if (index < domainScores.length - 1) {
          domainString = `${domainString}${domain},`;
        } else {
          domainString += domain;
        }
      });

      const params = {
        asmtEventId: parseInt(assessment!.value as string, 10),
        test: section!.value as string,
        domain: domainString,
      };

      getQbankTableData(params);
    }
  }, [assessment, section, domainScores, getQbankTableData]);

  useEffect(() => {
    setTags(generateTags(difficultyFilters, skillFilters));
  }, [difficultyFilters, skillFilters]);

  useEffect(() => {
    if (tags.length) {
      const filterTagsDiv = document.getElementById('filter-tags')! as HTMLDivElement;
      appendTagAriaLabels(filterTagsDiv);
    }
  }, [tags]);

  useEffect(() => {
    setPaginatedQuestions(paginate(filteredTableData, pageSize, currentPage));
  }, [filteredTableData, pageSize, currentPage]);

  useEffect(() => {
    const cbPagination = document.getElementsByClassName('cb-pagination');

    if (cbPagination.length) {
      const cbPaginationLength = cbPagination[0].children.length;
      switch (cbPaginationLength) {
        case 4:
          setPagerPadding(120);
          break;
        case 5:
          setPagerPadding(96);
          break;
        case 6:
          setPagerPadding(72);
          break;
        case 7:
          setPagerPadding(48);
          break;
        case 8:
          setPagerPadding(24);
          break;
        default:
          setPagerPadding(0);
      }
    }
  }, [paginatedQuestions]);

  useEffect(() => setCurrentPage(1), [tags, showSelectedQuestions, filterLiveItems]);

  if (!isLoaded) return <Spinner />;

  return (
    <div id="questionbank-table">
      <div className={windowWidth < 768 && showKsd ? 'main-content-hidden' : ''}>
        {!!tags.length && (
          <div id="filter-tags" className="cb-margin-top-8">
            <FilterTagSet tags={tags} onClick={onTagClick} />
          </div>
        )}
        <div className="table-header cb-margin-top-16 cb-padding-bottom-16">
          <p role="status">
            <span>{filteredTableData.length}</span> questions in results set.
          </p>
          {!!exportData.length && (
            <p role="status">
              <span className="highlighted">
                {`${exportData.length} question`}
                {exportData.length > 1 && 's'}
              </span>{' '}
              selected for export.
            </p>
          )}
          <div className="selected-all">
            <p className="cb-margin-right-8">Show</p>
            <NakedButton
              className={showSelectedQuestions ? 'highlighted show-selected' : 'inactive show-selected'}
              onClick={() => {
                setShowSelectedQuestions(true);
              }}
            >
              Selected Questions
            </NakedButton>
            <span> | </span>
            <NakedButton
              className={!showSelectedQuestions ? 'highlighted show-all' : 'inactive show-all'}
              onClick={() => {
                setShowSelectedQuestions(false);
              }}
            >
              All
            </NakedButton>
          </div>
        </div>
        <div className="table">
          <Table borderlessColumn responsive data={paginatedQuestions}>
            {getQuestionbankTableColumns()}
          </Table>
        </div>
        <div className="no-results" aria-live="polite" aria-atomic aria-relevant="all">
          {!filteredTableData.length && (
            <InfoNotification title={noResultsError.title}>
              <p>{noResultsError.description}</p>
            </InfoNotification>
          )}
        </div>
        {!!paginatedQuestions.length && (
          <>
            <div className="page-size">
              <p>View:</p>
              <NakedButton
                disabled={pageSize === 10}
                className={`${pageSize === 10 && 'selected'}`}
                onClick={() => {
                  setPageSize(10);
                  setCurrentPage(1);
                  window.scrollTo({ top: 1200 });
                }}
              >
                10
              </NakedButton>
              <p>|</p>
              <NakedButton
                disabled={pageSize === 30}
                className={`${pageSize === 30 && 'selected'}`}
                onClick={() => {
                  setPageSize(30);
                  setCurrentPage(1);
                }}
              >
                30
              </NakedButton>
              <p>|</p>
              <NakedButton
                disabled={pageSize === 50}
                className={`${pageSize === 50 && 'selected'}`}
                onClick={() => {
                  setPageSize(50);
                  setCurrentPage(1);
                }}
              >
                50
              </NakedButton>
            </div>
            <div className="pager-wrapper" style={{ paddingLeft: pagerPadding }}>
              <Pager
                current={currentPage}
                responsive
                max={Math.ceil(filteredTableData.length / pageSize)}
                delta={3}
                onPageChange={(newPage) => setCurrentPage(newPage)}
              />
            </div>
          </>
        )}
        <QbankQuestionModal />
      </div>
    </div>
  );
};

export default QuestionbankTable;
