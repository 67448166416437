import { LeftTextBand } from '@cb/apricot-react-band';
import { Breadcrumbs, Crumb } from '@cb/apricot-react-breadcrumbs';
import { useHistory } from 'react-router-dom';
import ROUTES from '../../../../../../routes';

const EssayDetailsHeader: React.FC<{ essayTitle: string }> = ({ essayTitle }) => {
  const history = useHistory();

  return (
    <header>
      <LeftTextBand
        title="SAT Practice Essay"
        description="Use the rubric to score your practice essay and view essay examples from other students."
        className="cb-blue2-bg cb-white-color"
        condensed
      />
      <Breadcrumbs pageTitle="" className="essay-breadcrumbs cb-gray5-bg">
        <Crumb title="My Tests" onClick={() => history.push(ROUTES.dashboard)} />
        <Crumb title={essayTitle} />
      </Breadcrumbs>
    </header>
  );
};

export default EssayDetailsHeader;
