import { useContext } from 'react';
import { SquareButton } from '@cb/apricot-react-button';
import TestResultsAccordion from '../../testResultsAccordion';
import './styles/index.scss';
import { scoresContext } from '../../../../../../contexts/api/ScoresProvider';

interface IViewPracticeScores {
  showKsd: boolean;
  setShowKsd: React.Dispatch<React.SetStateAction<boolean>>;
}

const ViewPracticeScores: React.FC<IViewPracticeScores> = ({ showKsd, setShowKsd }) => {
  const { isLoaded } = useContext(scoresContext);

  const getKsdIcon = () => {
    if (showKsd) return 'east';
    return 'west';
  };

  if (!isLoaded) return null;

  return (
    <div id="view-practice-scores" className={`cb-margin-top-48 display-flex ${showKsd && 'show-ksd'}`}>
      <div className="ksd-icon">
        <SquareButton
          aria-label="practice scores"
          icon={getKsdIcon()}
          label=""
          onClick={() => setShowKsd(!showKsd)}
          aria-expanded={showKsd}
        />
      </div>
      <div className="ksd-wrapper">
        <div className="ksd-header">
          <h2 className="cb-margin-bottom-8">View Your Practice Scores</h2>
          <p className="cb-margin-bottom-16">
            Review your practice test results to see which skills you need to strengthen and to help you choose the
            options for creating your question set.
          </p>
        </div>
        {showKsd && <TestResultsAccordion />}
      </div>
    </div>
  );
};

export default ViewPracticeScores;
