import { Tag } from '@cb/apricot-react-button';
import { Icon } from '@cb/apricot-react-icon';
import { SyntheticEvent } from 'react';

const FilterTagSet: React.FC<{ tags: string[]; onClick: (tagName: string, e: SyntheticEvent) => void }> = ({
  tags,
  onClick,
}) => {
  return (
    <div className="cb-btn-row cb-tag-set" role="region" aria-label="Applied Filters">
      <Icon name="tag" />
      <div className="tags">
        {tags.map((tag, i) => (
          <Tag aria-label={`Remove ${tag} filter`} key={i} icon="x-mark" onClick={(e) => onClick(tag, e)}>
            {tag}
          </Tag>
        ))}
      </div>
    </div>
  );
};

export default FilterTagSet;
