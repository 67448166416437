import { useState } from 'react';
import {
  SimpleAccordion,
  SimpleAccordionHeader,
  SimpleAccordionItem,
  SimpleAccordionPanel,
} from '@cb/apricot-react-accordion';
import { Spinner } from '@cb/apricot-react-spinner';
import { useFunctionalContentItems } from '@satui/drupal-profile';
import { HtmlMessage } from '@satreg/drupal';
import { RUBRIC_KEYS } from '../../../../drupal';
import './styles/index.scss';
import RubricRadioGroup from './rubricRadioGroup';
import { Icon } from '@cb/apricot-react-icon';
import { Tooltip } from '@cb/apricot-react-tooltip';

export type Tab = 'Writing' | 'Analysis' | 'Reading';

const ScoringRubricGuide: React.FC<{ header?: React.ReactElement }> = ({ header }) => {
  const Header = header;
  const [tab, setTab] = useState<Tab>('Reading');

  const { drupalContent } = useFunctionalContentItems(Object.values(RUBRIC_KEYS));

  if (!drupalContent) return <Spinner />;

  const {
    [RUBRIC_KEYS.READING_4]: reading4Content,
    [RUBRIC_KEYS.READING_3]: reading3Content,
    [RUBRIC_KEYS.READING_2]: reading2Content,
    [RUBRIC_KEYS.READING_1]: reading1Content,
    [RUBRIC_KEYS.ANALYSIS_4]: analysis4Content,
    [RUBRIC_KEYS.ANALYSIS_3]: analysis3Content,
    [RUBRIC_KEYS.ANALYSIS_2]: analysis2Content,
    [RUBRIC_KEYS.ANALYSIS_1]: analysis1Content,
    [RUBRIC_KEYS.WRITING_4]: writing4Content,
    [RUBRIC_KEYS.WRITING_3]: writing3Content,
    [RUBRIC_KEYS.WRITING_2]: writing2Content,
    [RUBRIC_KEYS.WRITING_1]: writing1Content,
  } = drupalContent;

  return (
    <div className="scoring-rubric-guide">
      {header ? (
        Header
      ) : (
        <div className="rubric-header">
          <h3 id="rubric-title">Scoring Rubric Guide</h3>
          <Icon name="info-circle" id="rubric-tooltip" />
          <Tooltip trigger="rubric-tooltip" placement="top">
            <p>
              You can self score your practice essay, but the SAT Essay response you&apos;ll submit on test day gets
              scored by two professionals using a carefully designed process.
            </p>
            {/* <a
              href="https://satsuite.collegeboard.org/sat/scores/understanding-scores/essay"
              rel="noreferrer"
              target="_blank"
            >
              Learn about essay scoring.
            </a> */}
          </Tooltip>
        </div>
      )}
      <div className="tab-row">
        <RubricRadioGroup tab={tab} setTab={setTab} />
      </div>
      <div className="rubric-accordion">
        <SimpleAccordion role="region" aria-labelledby={`rubric-title radio-${tab.toLowerCase()}`}>
          <SimpleAccordionItem>
            <SimpleAccordionHeader>
              <h4>Essay Score 4</h4>
            </SimpleAccordionHeader>
            <SimpleAccordionPanel>
              {tab === 'Reading' && <HtmlMessage message={reading4Content.overview || ''} />}
              {tab === 'Analysis' && <HtmlMessage message={analysis4Content.overview || ''} />}
              {tab === 'Writing' && <HtmlMessage message={writing4Content.overview || ''} />}
            </SimpleAccordionPanel>
          </SimpleAccordionItem>
          <SimpleAccordionItem>
            <SimpleAccordionHeader>
              <h4>Essay Score 3</h4>
            </SimpleAccordionHeader>
            <SimpleAccordionPanel>
              {tab === 'Reading' && <HtmlMessage message={reading3Content.overview || ''} />}
              {tab === 'Analysis' && <HtmlMessage message={analysis3Content.overview || ''} />}
              {tab === 'Writing' && <HtmlMessage message={writing3Content.overview || ''} />}
            </SimpleAccordionPanel>
          </SimpleAccordionItem>
          <SimpleAccordionItem>
            <SimpleAccordionHeader>
              <h4>Essay Score 2</h4>
            </SimpleAccordionHeader>
            <SimpleAccordionPanel>
              {tab === 'Reading' && <HtmlMessage message={reading2Content.overview || ''} />}
              {tab === 'Analysis' && <HtmlMessage message={analysis2Content.overview || ''} />}
              {tab === 'Writing' && <HtmlMessage message={writing2Content.overview || ''} />}
            </SimpleAccordionPanel>
          </SimpleAccordionItem>
          <SimpleAccordionItem>
            <SimpleAccordionHeader>
              <h4>Essay Score 1</h4>
            </SimpleAccordionHeader>
            <SimpleAccordionPanel>
              {tab === 'Reading' && <HtmlMessage message={reading1Content.overview || ''} />}
              {tab === 'Analysis' && <HtmlMessage message={analysis1Content.overview || ''} />}
              {tab === 'Writing' && <HtmlMessage message={writing1Content.overview || ''} />}
            </SimpleAccordionPanel>
          </SimpleAccordionItem>
        </SimpleAccordion>
      </div>
    </div>
  );
};

export default ScoringRubricGuide;
