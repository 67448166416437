import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Checkbox, CheckboxGroup, Select } from '@cb/apricot-react-forms';
import { ButtonRow, YellowButton, NakedButton, SquareButton } from '@cb/apricot-react-button';
import { useFunctionalContentItem } from '@satui/drupal-profile';
import { HtmlMessage } from '@satreg/drupal';
import ROUTES from '../../../../../routes';
import questionbankRoutes from '../routes';
import {
  assessmentSelectValues,
  mathCheckboxValues,
  readingWritingCheckboxValues,
  sectionSelectValues,
} from './constants';
import { searchQueryContext } from '../../../../../contexts/questionbank/searchQuery';
import { getAssessmentSelectValue, getSectionSelectValue, sortDomainScores } from './utils';
import TestResultsAccordion from '../testResultsAccordion';
import { SQB_SEARCH_KEYS } from '../../../../drupal';
import { scoresContext } from '../../../../../contexts/api/ScoresProvider';
import { IScoreEssayRes, IScoreObject } from '../../../../../types/scores';
import './styles/index.scss';

/**
 * Search form component for the Student Question Bank. The form uses progressive disclosure, only
 * displaying the next form element when the user has made a selection in the previous element.
 */
const Search: React.FC = () => {
  const { assessment, setAssessment, section, setSection, domainScores, setDomainScores } =
    useContext(searchQueryContext);
  const [sectionSelectValue, setSectionSelectValue] = useState('');
  const history = useHistory();
  const { scores } = useContext(scoresContext);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showKsd, setShowKsd] = useState(false);
  const [showKsdSection, setShowKsdSection] = useState(false);

  const getDomainCheckboxes = () => {
    const onChange = (checked: boolean, event: Event) => {
      const target = event.target as HTMLInputElement;

      if (checked) {
        setDomainScores(sortDomainScores(section!.value as string, [...domainScores, target.defaultValue]));
      } else {
        setDomainScores(
          sortDomainScores(
            section!.value as string,
            domainScores.filter((domain) => domain !== target.defaultValue),
          ),
        );
      }
    };

    if (section?.value === 'reading') {
      return readingWritingCheckboxValues.map((value, index) => {
        return (
          <Checkbox
            key={index}
            className={index === 0 ? 'first-checkbox' : ''}
            label={value.label}
            value={value.value}
            onChange={onChange}
            checked={domainScores.includes(value.value as string)}
          />
        );
      });
    }

    if (section?.value === 'math') {
      return mathCheckboxValues.map((value, index) => {
        return (
          <Checkbox
            key={index}
            className={index === 0 ? 'first-checkbox' : ''}
            label={value.label}
            value={value.value}
            onChange={onChange}
            checked={domainScores.includes(value.value as string)}
          />
        );
      });
    }

    return null;
  };

  const searchOnClick = () => {
    const searchQueryObj = { assessment, section, domainScores };
    // set user's query to session storage to facilitate remembering the query between hard refreshes
    window.sessionStorage.setItem('sqbSearchQuery', JSON.stringify(searchQueryObj));
    history.push(questionbankRoutes.results);
  };

  const getKsdIcon = () => {
    if (showKsd) return 'east';
    return 'west';
  };

  const domainsDescriptionContent = useFunctionalContentItem(SQB_SEARCH_KEYS.SQB_DOMAINS_DESCRIPTION);

  // on load, clear previous search query and set title
  // add a resize event listener with a callback function to update the windowWidth state.
  useEffect(() => {
    setAssessment(null);
    setSection(null);
    setDomainScores([]);

    document.title = 'MyPractice - Question Bank - Search';

    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth >= 768) setShowKsd(true);
  }, [windowWidth]);

  useEffect(() => {
    setDomainScores([]);
  }, [section]);

  useEffect(() => {
    if (scores.length === 0) return;

    scores.map((score) => {
      const isScoreEssayRes = (s: IScoreObject): s is IScoreEssayRes => (s as IScoreEssayRes).essay !== undefined;
      if (isScoreEssayRes(score) || !score.questionBankData) return null;
      return setShowKsdSection(true);
    });
  }, [scores]);

  return (
    <div id="questionbank-search" className="container cb-padding-top-72">
      {windowWidth < 768 && showKsd && (
        <div className="knowledge-skills cb-margin-top-16 show-ksd">
          <div className={`ksd-icon display-flex justify-content-end ${!showKsd && 'ksd-hidden'}`}>
            <SquareButton
              aria-label="practice scores"
              icon={getKsdIcon()}
              label=""
              onClick={() => setShowKsd(!showKsd)}
              aria-expanded={showKsd}
            />
          </div>
          <div className="ksd-content-mobile">
            <div className="ksd-header">
              <h3 className="cb-margin-bottom-8">View Your Practice Scores</h3>
              <p className="cb-margin-bottom-16">
                Review your practice test results to see which skills you need to strengthen and to help you choose the
                options for creating your question set.
              </p>
            </div>
            <TestResultsAccordion />
          </div>
        </div>
      )}
      <div className={`row ${windowWidth < 768 && showKsd && 'main-content-hidden'}`}>
        <div className={`search-form ${windowWidth > 767 ? 'col-xs-6' : 'col-xs-12'}`}>
          <h2 className="cb-margin-bottom-8">Create a Question Set</h2>
          <p className="description">
            Use the options below to tailor your practice to the questions you need to review the most.
          </p>
          {windowWidth < 768 && showKsdSection && (
            <div className="knowledge-skills cb-margin-top-16">
              <div className={`ksd-icon display-flex justify-content-end ${!showKsd && 'ksd-hidden'}`}>
                <SquareButton icon={getKsdIcon()} label="" onClick={() => setShowKsd(!showKsd)} />
              </div>
              <div className="ksd-header ksd-header-mobile">
                <h3 className="cb-margin-bottom-8">View Your Practice Scores</h3>
                <p className="cb-margin-bottom-16">
                  Review your practice test results to see which skills you need to strengthen and to help you choose
                  the options for creating your question set.
                </p>
              </div>
            </div>
          )}
          <div className="assessment-select cb-margin-top-32">
            <h3 className="cb-margin-bottom-4">
              <span>Step 1:</span> Choose Your Assessment
            </h3>
            <p className="description cb-margin-bottom-24">
              Select which assessment to practice. View your practice scores to help you choose.
            </p>
            <Select
              onChange={(value) => {
                setSection(sectionSelectValues[0]);
                setSectionSelectValue('');
                setAssessment(getAssessmentSelectValue(value)!);
              }}
              values={assessmentSelectValues}
              aria-label="Select Assessment"
              label="Assessment"
            />
          </div>
          {assessment?.value && (
            <div className="assessment-select cb-margin-top-32">
              <h3 className="cb-margin-bottom-4">
                <span>Step 2:</span> Choose Your Section
              </h3>
              <p className="description cb-margin-bottom-24">
                Select the test section where you need the most practice. View your practice scores to help you choose.
              </p>
              <Select
                onChange={(value) => {
                  setSectionSelectValue(value);
                  setSection(getSectionSelectValue(value)!);
                }}
                values={sectionSelectValues}
                value={sectionSelectValue}
                aria-label="Choose Section"
                label="Section"
              />
            </div>
          )}
          {assessment?.value && section?.value && (
            <div className="content-domain cb-margin-top-32">
              <h3 className="cb-margin-bottom-4">
                <span>Step 3:</span> Find Questions by Domain Scores
              </h3>
              <HtmlMessage message={domainsDescriptionContent.content?.overview || ''} />
              <CheckboxGroup vertical>{getDomainCheckboxes()}</CheckboxGroup>
            </div>
          )}
          <ButtonRow align="end">
            <NakedButton disabled={windowWidth < 768 && showKsd} onClick={() => history.push(ROUTES.dashboard)}>
              Cancel
            </NakedButton>
            <YellowButton disabled={!domainScores.length} onClick={searchOnClick}>
              Continue
            </YellowButton>
          </ButtonRow>
        </div>
        {windowWidth >= 768 && (
          <>
            {windowWidth > 1022 && <div className="offset-xs-1" />}
            {windowWidth <= 1023 && (
              <div className={`ksd-icon col-xs-1 display-flex justify-content-end ${!showKsd && 'ksd-hidden'}`}>
                <SquareButton icon={getKsdIcon()} label="" onClick={() => setShowKsd(!showKsd)} />
              </div>
            )}
            {showKsdSection && (
              <div className={`knowledge-skills col-xs-5 ${!showKsd && 'ksd-hidden'}`}>
                <div className="ksd-header">
                  <h3 className="cb-margin-bottom-8">View Your Practice Scores</h3>
                  <p className="cb-margin-bottom-16">
                    Review your practice test results to see which skills you need to strengthen and to help you choose
                    the options for creating your question set.
                  </p>
                </div>
                {showKsd && <TestResultsAccordion />}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Search;
