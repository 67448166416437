import { PrimaryButton, SecondaryButton } from '@cb/apricot-react-button';
import { useContext, useEffect, useState } from 'react';
import { qbankQuestionModalContext } from '../../../../../../../../contexts/questionbank/questionModal';
import { filteredTableDataContext } from '../../../../../../../../contexts/questionbank/filteredTableData';
import { exportQuestionSetContext } from '../../../../../../../../contexts/questionbank/exportQuestionSet';

const ModalFooter: React.FC<{ onCloseModal: () => void }> = ({ onCloseModal }) => {
  const { selectedRow, selectedRowIndex, isModalOpen } = useContext(qbankQuestionModalContext);
  const { markCheckedState, pageSize, currentPage } = useContext(filteredTableDataContext);
  const { addQuestion, removeQuestion } = useContext(exportQuestionSetContext);
  const [addPdf, setAddPdf] = useState(false);

  /**
   * Function to either add the selected question to the list of exported questions,
   * or remove it if it is already selected.
   */
  const addToPdf = () => {
    // if question is already added to the PDF list, remove it. Otherwise, add it.
    if (addPdf) {
      markCheckedState(selectedRowIndex! + (currentPage - 1) * pageSize, true);
      addQuestion(selectedRow!);
      setAddPdf(false);
    } else {
      markCheckedState(selectedRowIndex! + (currentPage - 1) * pageSize, false);
      removeQuestion(selectedRow!.questionId);
      setAddPdf(true);
    }
  };

  useEffect(() => {
    if (selectedRow) setAddPdf(!selectedRow.checked);
  }, [selectedRow, isModalOpen]);

  return (
    <div className="row">
      <div className="col-xs-12 cb-margin-top-8 cb-align-right">
        <SecondaryButton className="square" onClick={onCloseModal}>
          Cancel
        </SecondaryButton>
        <PrimaryButton className="cb-margin-left-24 square" onClick={addToPdf}>
          {addPdf ? 'Add to PDF' : 'Remove from PDF'}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default ModalFooter;
