import { useContext, useState, useEffect } from 'react';
import { BlackButton, NakedButton, SquareButton } from '@cb/apricot-react-button';
import { useHistory } from 'react-router-dom';
import { Popover } from '@cb/apricot-react-popover';
import { RadioButton, RadioButtonGroup } from '@cb/apricot-react-forms';
import { Spinner } from '@cb/apricot-react-spinner';
import { Icon } from '@cb/apricot-react-icon';
import { exportQuestionSetContext } from '../../../../../../contexts/questionbank/exportQuestionSet';
import { qbankQuestionDataContext } from '../../../../../../contexts/questionbank/api/QbankQuestionDataProvider';
import questionbankRoutes from '../../routes';
import { searchQueryContext } from '../../../../../../contexts/questionbank/searchQuery';
import { scoresContext } from '../../../../../../contexts/api/ScoresProvider';
import { getDomainScoresString } from '../utils';
import LiveItemsFilter from './liveItems';
import QuestionSet from './exportedQuestionSet';
import DifficultyCheckboxGroup from './dropdownCheckboxGroups/DifficultyCheckboxGroup';
import SkillCheckboxGroup from './dropdownCheckboxGroups/SkillCheckboxGroup';
import { filteredTableDataContext } from '../../../../../../contexts/questionbank/filteredTableData';
import TestResultsAccordion from '../../testResultsAccordion';
import './styles/index.scss';

interface IFiltersExport {
  windowWidth: number;
  showKsd: boolean;
  setShowKsd: React.Dispatch<React.SetStateAction<boolean>>;
  hasKSD: () => boolean;
}

const FiltersExport: React.FC<IFiltersExport> = ({ windowWidth, showKsd, setShowKsd, hasKSD }) => {
  const { assessment, setAssessment, section, setSection, domainScores, setDomainScores } =
    useContext(searchQueryContext);
  const { exportData, dataLoaded, setDataLoaded } = useContext(exportQuestionSetContext);
  const { multipleQbankQuestionData, getMultipleQbankQuestionData, clearMultipleQbankQuestionData } =
    useContext(qbankQuestionDataContext);
  const { isLoaded: scoresLoaded } = useContext(scoresContext);

  const { difficultyFilters, skillFilters } = useContext(filteredTableDataContext);
  const [currentSelectedDifficulties, setCurrentSelectedDifficulties] = useState(difficultyFilters);
  const [currentSelectedSkills, setCurrentSelectedSkills] = useState(skillFilters);

  const [withHeaders, setWithHeaders] = useState(true);
  const [withCorrectAnswers, setWithCorrectAnswers] = useState(true);
  const [printDisabled, setPrintDisabled] = useState(true);

  const history = useHistory();

  const getDifficultyButtonLabel = () => {
    const checkedDifficulties = difficultyFilters.filter((difficulty) => difficulty.checked);
    if (!checkedDifficulties.length) return 'Difficulty';
    return `Difficulty (${checkedDifficulties.length})`;
  };

  const getSkillButtonLabel = () => {
    const checkedSkills = skillFilters.map((skillFilter) =>
      skillFilter.checkboxes.filter((checkbox) => checkbox.checked),
    );
    const totalCheckedSkills = checkedSkills.reduce((acc, curr) => acc + curr.length, 0);
    if (!totalCheckedSkills) return 'Skill';
    return `Skill (${totalCheckedSkills})`;
  };

  const onExportClick = () => {
    getMultipleQbankQuestionData();
    setDataLoaded(true);
  };

  const onExplanationChange = (value: string) => {
    if (value === 'no-explanations') {
      setWithHeaders(true);
      setWithCorrectAnswers(false);
    }

    if (value === 'with-explanations') {
      setWithHeaders(true);
      setWithCorrectAnswers(true);
    }

    if (value === 'no-headers') {
      setWithHeaders(false);
      setWithCorrectAnswers(false);
    }

    setPrintDisabled(false);
  };

  const getKsdIcon = () => {
    if (showKsd) return 'east';
    return 'west';
  };

  /**
   * Upon export question data being loaded, sets an event listener to the document that closes
   * and resets the exportPopoverEl upon clicking outside the popover
   */
  useEffect(() => {
    const exportPopoverEl = document.getElementsByClassName('export-popover')[0];

    const resetExportQuestions = (e: MouseEvent) => {
      // if user clicks outside of popover, clear data and reset the popover
      if (!exportPopoverEl.contains(e.target as Node)) {
        document.removeEventListener('click', resetExportQuestions);
        setDataLoaded(false);
        clearMultipleQbankQuestionData();
        setPrintDisabled(true);
      }
    };

    // if data is loaded, add the event listener
    if (dataLoaded) {
      setTimeout(() => {
        document.addEventListener('click', resetExportQuestions);
      }, 500);
    }
  }, [dataLoaded]);

  return (
    <div id="filters-export" className="cb-margin-top-48">
      {windowWidth < 768 && showKsd && (
        <div className="knowledge-skills cb-margin-top-16 show-ksd">
          <div className={`ksd-icon display-flex justify-content-end ${!showKsd && 'ksd-hidden'}`}>
            <SquareButton
              aria-label="practice scores"
              icon={getKsdIcon()}
              label=""
              onClick={() => setShowKsd(!showKsd)}
              aria-expanded={showKsd}
            />
          </div>
          <div className="ksd-header">
            <h3 className="cb-margin-bottom-8">View Your Practice Scores</h3>
            <p className="cb-margin-bottom-16">
              Review your practice test results to see which skills you need to strengthen and to help you choose the
              options for creating your question set.
            </p>
          </div>
          <TestResultsAccordion />
        </div>
      )}
      <div className={windowWidth < 768 && showKsd ? 'main-content-hidden' : ''}>
        <div className="search-criteria">
          <div className="header display-flex align-items-center">
            <h3 className="cb-margin-right-16">Your Search Criteria</h3>
            <NakedButton
              onClick={() => {
                setAssessment(null);
                setSection(null);
                setDomainScores([]);

                window.scrollTo({ top: 0 });
                history.push(questionbankRoutes.search);
              }}
              className="cb-no-padding"
            >
              New Search
            </NakedButton>
          </div>
          <div className="display-flex flex-wrap">
            <p>
              <span>Assessment: </span>
              {assessment?.label}
            </p>
            <p>
              <span>Section: </span>
              {section?.label}
            </p>
            {domainScores.map((domainScore, index) => (
              <p key={index}>
                <span>Domain: </span>
                {getDomainScoresString(section?.value as string, [domainScore])}
              </p>
            ))}
          </div>
        </div>
        {windowWidth < 768 && scoresLoaded && hasKSD() && (
          <div className="knowledge-skills cb-margin-top-16">
            <div className={`ksd-icon display-flex justify-content-end ${!showKsd && 'ksd-hidden'}`}>
              <SquareButton icon={getKsdIcon()} label="" onClick={() => setShowKsd(!showKsd)} />
            </div>
            <div className="ksd-header">
              <h3 className="cb-margin-bottom-8">View Your Practice Scores</h3>
              <p className="cb-margin-bottom-16">
                Review your practice test results to see which skills you need to strengthen and to help you choose the
                options for creating your question set.
              </p>
            </div>
          </div>
        )}
        {windowWidth > 500 ? (
          <div className="display-flex justify-content-between align-items-center cb-margin-top-24">
            <div className="table-filters">
              <h3>Add Filters:</h3>
              <BlackButton
                aria-label="Filter by difficulty"
                id="difficulty-filter"
                className={`difficulty ${difficultyFilters.some((difficulty) => difficulty.checked) && 'selected'}`}
                onClick={() => setCurrentSelectedDifficulties(difficultyFilters)}
              >
                {getDifficultyButtonLabel()}
              </BlackButton>
              <Popover placement="bottom" trigger="difficulty-filter" popoverTitle="Select Difficulty" closeButton>
                <DifficultyCheckboxGroup
                  currentSelectedDifficulties={currentSelectedDifficulties}
                  setCurrentSelectedDifficulties={setCurrentSelectedDifficulties}
                />
              </Popover>
              <BlackButton
                aria-label="Filter by skill"
                id="skill-filter"
                className={`skill ${skillFilters.some((group) => group.checkboxes.some((checkbox) => checkbox.checked)) && 'selected'}`}
                onClick={() => setCurrentSelectedSkills(skillFilters)}
              >
                {getSkillButtonLabel()}
              </BlackButton>
              <Popover placement="bottom" trigger="skill-filter" popoverTitle="Select Skill" closeButton>
                <SkillCheckboxGroup
                  currentSelectedSkills={currentSelectedSkills}
                  setCurrentSelectedSkills={setCurrentSelectedSkills}
                />
              </Popover>
              <div className="live-items-filter cb-margin-left-4">
                <LiveItemsFilter />
              </div>
            </div>
            <div className="export-container cb-margin-xs-top-8 cb-no-margin-sm-top">
              <BlackButton id="export-button" aria-haspopup disabled={!exportData.length} onClick={onExportClick}>
                <Icon name="download" />
                <p>Export</p>
              </BlackButton>
              <Popover placement="bottom" trigger="export-button" className="export-popover">
                {multipleQbankQuestionData.length === exportData.length ? (
                  <>
                    <div className="cb-font-size-small cb-margin-bottom-16">
                      <label id="export-menu" className="sr-only" htmlFor="export-radio-group">
                        Export PDF Menu
                      </label>
                      <RadioButtonGroup
                        ariaLabelledBy="export-menu"
                        name="export-radio-group"
                        vertical
                        vSpacing="8"
                        onChange={onExplanationChange}
                      >
                        <RadioButton label="No correct answers or explanations" value="no-explanations" />
                        <RadioButton label="With correct answers and explanations" value="with-explanations" />
                        <RadioButton label="Without answers or headers" value="no-headers" />
                      </RadioButtonGroup>
                    </div>
                    <div className="display-flex justify-content-center">
                      <QuestionSet
                        withHeaders={withHeaders}
                        withCorrectAnswers={withCorrectAnswers}
                        printDisabled={printDisabled}
                      />
                    </div>
                  </>
                ) : (
                  <div className="popover-spinner display-flex justify-content-center">
                    <Spinner />
                  </div>
                )}
              </Popover>
            </div>
          </div>
        ) : (
          <div className="cb-margin-top-24" id="mobile-filters">
            <h3>Add Filters:</h3>
            <BlackButton
              aria-label="Filter by difficulty"
              id="difficulty-filter"
              className={`difficulty ${difficultyFilters.some((difficulty) => difficulty.checked) && 'selected'}`}
              onClick={() => setCurrentSelectedDifficulties(difficultyFilters)}
            >
              {getDifficultyButtonLabel()}
            </BlackButton>
            <Popover placement="bottom" trigger="difficulty-filter" popoverTitle="Select Difficulty" closeButton>
              <DifficultyCheckboxGroup
                currentSelectedDifficulties={currentSelectedDifficulties}
                setCurrentSelectedDifficulties={setCurrentSelectedDifficulties}
              />
            </Popover>
            <BlackButton
              aria-label="Filter by skill"
              id="skill-filter"
              className={`skill ${skillFilters.some((group) => group.checkboxes.some((checkbox) => checkbox.checked)) && 'selected'}`}
              onClick={() => setCurrentSelectedSkills(skillFilters)}
            >
              {getSkillButtonLabel()}
            </BlackButton>
            <Popover placement="bottom" trigger="skill-filter" popoverTitle="Select Skill" closeButton>
              <SkillCheckboxGroup
                currentSelectedSkills={currentSelectedSkills}
                setCurrentSelectedSkills={setCurrentSelectedSkills}
              />
            </Popover>
            <div className="live-items-filter cb-margin-left-4">
              <LiveItemsFilter />
            </div>
            <div className="export-container cb-margin-xs-top-8 cb-no-margin-sm-top">
              <BlackButton id="export-button" aria-haspopup disabled={!exportData.length} onClick={onExportClick}>
                <Icon name="download" />
                <p>Export</p>
              </BlackButton>
              <Popover placement="bottom" trigger="export-button" className="export-popover">
                {multipleQbankQuestionData.length === exportData.length ? (
                  <>
                    <div className="cb-font-size-small cb-margin-bottom-16">
                      <label id="export-menu" className="sr-only" htmlFor="export-radio-group">
                        Export PDF Menu
                      </label>
                      <RadioButtonGroup
                        ariaLabelledBy="export-menu"
                        name="export-radio-group"
                        vertical
                        vSpacing="8"
                        onChange={onExplanationChange}
                      >
                        <RadioButton label="No correct answers or explanations" value="no-explanations" />
                        <RadioButton label="With correct answers and explanations" value="with-explanations" />
                        <RadioButton label="Without answers or headers" value="no-headers" />
                      </RadioButtonGroup>
                    </div>
                    <div className="display-flex justify-content-center">
                      <QuestionSet
                        withHeaders={withHeaders}
                        withCorrectAnswers={withCorrectAnswers}
                        printDisabled={printDisabled}
                      />
                    </div>
                  </>
                ) : (
                  <div className="popover-spinner display-flex justify-content-center">
                    <Spinner />
                  </div>
                )}
              </Popover>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FiltersExport;
