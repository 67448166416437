import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { IQbankQuestionData, IRawQbankQuestionRes } from './types';
import { getCatapultHeaders, getTempHeaders } from '../../api/utils';
import { formatQuestionData } from './utils';
import { exportQuestionSetContext } from '../exportQuestionSet';

interface IQbankQuestionDataContext {
  getQbankQuestionData: (externalId: string) => void;
  clearQbankQuestionData: () => void;
  qbankQuestionData?: IQbankQuestionData;
  qbankQuestionLoaded: boolean;
  getMultipleQbankQuestionData: () => void;
  clearMultipleQbankQuestionData: () => void;
  multipleQbankQuestionData: IQbankQuestionData[];
  multipleQbankQuestionDataLoaded: boolean;
  error: boolean;
}

const defaultState: IQbankQuestionDataContext = {
  getQbankQuestionData: () => {},
  clearQbankQuestionData: () => {},
  qbankQuestionLoaded: false,
  getMultipleQbankQuestionData: () => {},
  clearMultipleQbankQuestionData: () => {},
  multipleQbankQuestionData: [],
  multipleQbankQuestionDataLoaded: false,
  error: false,
};

export const qbankQuestionDataContext = createContext<IQbankQuestionDataContext>(defaultState);
const { Provider } = qbankQuestionDataContext;

/**
 * Provides functions for retrieving question data for one or multiple questions from the api, and provides the responses.
 */
const QbankQuestionDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [qbankQuestionData, setQbankQuestionData] = useState<IQbankQuestionData>();
  const [qbankQuestionLoaded, setQbankQuestionLoaded] = useState(false);
  const [multipleQbankQuestionData, setMultipleQbankQuestionData] = useState<IQbankQuestionData[]>([]);
  const [multipleQbankQuestionDataLoaded, setMultipleQbankQuestionDataLoaded] = useState(false);
  const [error, setError] = useState(false);

  const { exportData } = useContext(exportQuestionSetContext);
  const cookies = new Cookies();

  /**
   * Makes a call go get data for a single question from the API, and sets the response to state
   */
  const getQbankQuestionData = useMemo(() => {
    const getData = (externalId: string) => {
      setQbankQuestionData(undefined);
      setQbankQuestionLoaded(false);
      setError(false);

      const params = {
        external_id: externalId,
      };

      const tempCookie = cookies.get('__Host-tempJwtToken');
      const headers = tempCookie ? getTempHeaders() : getCatapultHeaders();

      axios
        .post(process.env.REACT_APP_SQB_GET_QUESTION_ENDPOINT!, params, { headers })
        .then(({ data }: { data: IRawQbankQuestionRes }) => {
          setQbankQuestionData(formatQuestionData(data));
          setQbankQuestionLoaded(true);
        })
        .catch(() => {
          setError(true);
          setQbankQuestionLoaded(true);
        });
    };
    return getData;
  }, []);

  /**
   * Function to clear single question data from state
   */
  const clearQbankQuestionData = () => {
    setQbankQuestionData(undefined);
    setQbankQuestionLoaded(false);
    setError(false);
  };

  /**
   * Makes a call go get data for multiple questions from the API, and sets the response to state
   */
  const getMultipleQbankQuestionData = useMemo(() => {
    const getData = async () => {
      const paramsArray = exportData.map((row) => row.externalId);
      const params = { external_ids: paramsArray };

      const tempCookie = cookies.get('__Host-tempJwtToken');
      const headers = tempCookie ? getTempHeaders() : getCatapultHeaders();

      try {
        const { data }: { data: IRawQbankQuestionRes[] } = await axios.post(
          process.env.REACT_APP_SQB_PDF_DOWNLOAD_ENDPOINT!,
          params,
          { headers },
        );

        const fetchedQuestionsData: IQbankQuestionData[] = data.map((d) => formatQuestionData(d));

        setMultipleQbankQuestionData(fetchedQuestionsData);
        setMultipleQbankQuestionDataLoaded(true);
      } catch {
        setError(true);
        setMultipleQbankQuestionDataLoaded(true);
      }
    };
    return getData;
  }, [exportData]);

  /**
   * Function to clear multiple questions data from state
   */
  const clearMultipleQbankQuestionData = () => {
    setMultipleQbankQuestionData([]);
    setMultipleQbankQuestionDataLoaded(false);
    setError(false);
  };

  useEffect(() => {
    if (error) throw new Error('Questionbank question service unavailable');
  }, [error]);

  return (
    <Provider
      value={{
        getQbankQuestionData,
        clearQbankQuestionData,
        qbankQuestionData,
        qbankQuestionLoaded,
        getMultipleQbankQuestionData,
        clearMultipleQbankQuestionData,
        multipleQbankQuestionData,
        multipleQbankQuestionDataLoaded,
        error,
      }}
    >
      {children}
    </Provider>
  );
};

export default QbankQuestionDataProvider;
